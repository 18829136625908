import React, { useEffect, useRef, useState } from 'react'
import PaySupplier from './PaySupplier'
import Button from '../../../../components/Button'
import api from '../../../../api'
import { customFuncsApi } from '../../../../api'
import _, { get } from 'lodash'
import Layout from '../../../../components/Layout'
import { ErrorModal } from '../../../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import moment from 'moment'
import { roundCurrency } from '../../../../utils'
import { Empty } from 'antd'
import { useTranslation } from 'react-i18next'
import ViewPaySupplierPurchaseModal from '../../../../components/Modal/ViewPaySupplierPurchaseModal'

const PaymentSupplierPage = () => {
	const { t } = useTranslation()
	const inputRef = useRef(null)
	const oneRowRef = useRef(null)

	const [isLoading, setIsLoading] = useState(false)
	const [date, setDate] = useState(new Date().toISOString().split('T')[0])
	const errorRef = useRef()
	const [currency, setCurrency] = useState('USD')
	const [cashAccountsData, setCashAccountsData] = useState([])
	const [formData, setFormData] = useState({
		CardCode: '',
		CashAccount: '',
	})
	const [debtsData, setDebtsData] = useState([])
	const [remarks, setRemarks] = useState(null)
	const [businessPartnersData, setBusinessPartnersData] = useState([])
	const [paymentInvoices, setPaymentInvoices] = useState({})
	const [inputCardName, setInputCardName] = useState('')

	const getBusinessPartners = async (name = '') => {
		const formattedName = name.includes("'") ? name.replace(/'/g, "''") : name
		if (formattedName.length <= 0) {
			setBusinessPartnersData([])
			return
		}

		const data = await api(
			`BusinessPartners?$select=CardCode,CardName&$filter=CardType eq 'cSupplier' and contains(CardName,'${formattedName}')`,
		).then((res) => {
			return JSON.parse(res.data).value
		})

		const selectElementData = data.map((item) => {
			return {
				value: item.CardCode,
				label: item.CardName,
			}
		})

		setBusinessPartnersData(selectElementData)
	}

	const handleBusinessPartner = _.debounce((e) => {
		const val = e.target.value
		setInputCardName(val)
		if (val === '') {
			setFormData((prev) => ({
				...prev,
				CardCode: '',
			}))
		}
		getBusinessPartners(val)
	}, 1000)

	const handleBusinessPartnerClick = (event, code) => {
		if (inputRef.current) {
			inputRef.current.value = event.target.innerText
		}
		setFormData((prev) => ({
			...prev,
			CardCode: code,
		}))
		setBusinessPartnersData([])
	}

	const cashAccounts = async () => {
		const data = await api(
			`ChartOfAccounts?$select=Code,Name&$filter=startswith(Code, '5') and ExternalCode eq '1' and (AcctCurrency eq '${currency}' or AcctCurrency eq '%23%23')`,
		).then((res) => {
			return JSON.parse(res.data).value
		})
		setCashAccountsData(data)
		setFormData((prev) => ({
			...prev,
			CashAccount: data[0]?.Code,
		}))
	}

	useEffect(() => {
		cashAccounts()
	}, [currency])

	const debts = async (cardCode) => {
		const data = await customFuncsApi
			.get(`XsSql/getPurchasesForPayment?CardCode='${cardCode}'`, {
				headers: { Prefer: 'odata.maxpagesize=10000' },
			})
			.then((res) => {
				return res.data.value
			})
		setDebtsData(data)
	}

	useEffect(() => {
		debts(
			formData.CardCode.length > 0 && inputCardName ? formData.CardCode : '',
		)
	}, [formData.CardCode, inputCardName])

	const submitForm = async () => {
		try {
			let cashSum = 0
			for (const key in paymentInvoices) {
				if (paymentInvoices.hasOwnProperty(key)) {
					cashSum += +paymentInvoices[key].SumApplied
				}
			}

			const processedPaymentInvoices = []
			let rate = 1

			if (currency === 'UZS') {
				await api
					.post(`SBOBobService_GetCurrencyRate`, {
						Currency: 'UZS',
						Date: moment(date).format('YYYY-MM-DD'),
					})
					.then((res) => {
						rate = res.data
					})
			}

			for (const key in paymentInvoices) {
				if (paymentInvoices.hasOwnProperty(key)) {
					if (paymentInvoices[key].SumApplied > 0) {
						if (currency === 'USD') {
							processedPaymentInvoices.push({
								DocEntry: paymentInvoices[key].DocEntry,
								SumApplied: +paymentInvoices[key].SumApplied,
								InvoiceType: 'it_PurchaseInvoice',
							})
						} else {
							processedPaymentInvoices.push({
								DocEntry: paymentInvoices[key].DocEntry,
								SumApplied: roundCurrency(
									+paymentInvoices[key].SumApplied / +rate,
								),
								InvoiceType: 'it_PurchaseInvoice',
							})
						}
					}
				}
			}

			await api.post(`VendorPayments`, {
				DocDate: date + 'T00:00:00Z',
				DocType: 'rSupplier',
				CardCode: formData.CardCode,
				CashAccount: formData.CashAccount,
				Remarks: remarks && null,
				DocCurrency: currency,
				CashSum: cashSum,
				U_CashFlow: '2',
				PaymentInvoices: processedPaymentInvoices,
			})
			window.open('/outgoing-payment', '_blank')
		} catch (err) {
			if (isAxiosError(err)) {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			}
		}
	}

	const handleDateChange = (event) => {
		setDate(event.target.value)
	}

	const handleCurrencyChange = (event) => {
		setCurrency(event.target.value)
	}

	const handleClickCashAccount = (event) => {
		setFormData((prev) => ({
			...prev,
			CashAccount: event.target.value,
		}))
	}

	const handleRemarks = (event) => {
		setRemarks(event.target.value)
	}

	const handlePayingSum = (event, DocEntry) => {
		setPaymentInvoices((prev) => ({
			...prev,
			[DocEntry]: { DocEntry, SumApplied: event.target.value },
		}))
	}

	return (
		<Layout>
			<PaySupplier>
				<div className="container p-8">
					<p className="productTitle text-xl font-bold mb-8">
						{t('customerPayment')}
					</p>

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<div>
							<div
								className={'flex flex-col sm:flex-row gap-5 items-center mb-7'}
							>
								<div className="searchable-select flex-1 w-full">
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										{t('client')}{' '}
									</p>
									<input
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full sm:w-auto'
										}
										ref={inputRef}
										type="text"
										onChange={handleBusinessPartner}
									/>
									{!!businessPartnersData.length && (
										<div className="searchable-select-list">
											{businessPartnersData.map((item) => {
												return (
													<div
														key={item.value}
														className="searchable-select-item"
														onClick={(event) =>
															handleBusinessPartnerClick(event, item.value)
														}
													>
														{item.label}
													</div>
												)
											})}
										</div>
									)}
								</div>

								<div className={'flex-1 w-full'}>
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										{t('date')}
									</p>
									<input
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full sm:w-auto'
										}
										type="date"
										defaultValue={date}
										onChange={handleDateChange}
									/>
								</div>
							</div>
							<div className={'flex gap-6 flex-wrap mb-4 items-start'}>
								<div className={'min-w-[150px] '}>
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										{t('currency')}
									</p>
									<select
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full h-8'
										}
										value={currency}
										onChange={handleCurrencyChange}
									>
										<option value="UZS">UZS</option>
										<option value="USD">USD</option>
									</select>
								</div>
								<div className={'min-w-[250px]'}>
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										{t('schot')}
									</p>
									<select
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full h-8'
										}
										onChange={handleClickCashAccount}
									>
										{cashAccountsData.map((item) => {
											return (
												<option key={item?.Code} value={item?.Code}>
													{item.Name} - {item?.Code}
												</option>
											)
										})}
									</select>
								</div>
								<div>
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										{t('comment')}
									</p>
									<textarea
										className={
											'border-[1px] border-[#DFE2E9"] w-full h-8 w-full p-1 sm:w-[400px] '
										}
										onChange={handleRemarks}
									></textarea>
								</div>
								<Button className="!h-8 mt-5" onClick={submitForm}>
									{t('add2')}
								</Button>
							</div>

							<div>
								<table
									className={
										'w-full text-sm text-left rtl:text-right text-gray-500  mb-4 mt-10'
									}
								>
									<thead
										className={'text-xs text-gray-700 uppercase bg-gray-50'}
									>
										<tr>
											<th scope="col" className="px-6 py-3">
												{t('docNum')}
											</th>
											<th scope="col" className="px-6 py-3">
												{t('supplierCode')}
											</th>
											<th scope="col" className="px-6 py-3">
												{t('supplierName')}
											</th>
											<th scope="col" className="px-6 py-3">
												{t('docDate')}
											</th>
											<th scope="col" className="px-6 py-3">
												{t('docTotal')}
											</th>
											<th scope="col" className="px-6 py-3">
												{t('paid')}
											</th>{' '}
											<th scope="col" className="px-6 py-3">
												{t('debt')}
											</th>
											<th scope="col" className="px-6 py-3">
												{t('price')}
											</th>
										</tr>
									</thead>
									<tbody>
										{debtsData.length > 0 ? (
											debtsData.map((item) => {
												return (
													<tr
														className="bg-white border-b "
														key={item.DocEntry}
														onClick={() => oneRowRef.current?.open(item)}
													>
														<td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
															{item.DocNum}
														</td>
														<td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
															{item.CardCode}
														</td>
														<td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
															{item.CardName}
														</td>
														<td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
															{moment(item.DocDate).format('DD-MM-YYYY')}
														</td>
														<td className="px-6 py-4">{+item.DocTotal}</td>
														<td className="px-6 py-4">{item.PaidToDate}</td>
														<td className="px-6 py-4">
															{+item.DocTotal - item.PaidToDate}{' '}
															{item.DocCurrency}
														</td>
														<td
															className="px-6 py-4"
															onClick={(e) => e.stopPropagation()}
														>
															<input
																className={
																	'border-[1px] border-[#DFE2E9"] p-2 rounded-md'
																}
																type="number"
																onChange={(event) => {
																	handlePayingSum(event, item.DocEntry)
																}}
																disabled={
																	+item.DocTotal - item.PaidToDate === 0
																}
															/>
														</td>
													</tr>
												)
											})
										) : (
											<tr>
												<td colSpan="10">
													<Empty
														description={t('noInfo')}
														image={Empty.PRESENTED_IMAGE_SIMPLE}
													/>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</div>
					)}
				</div>
			</PaySupplier>
			<>
				<ViewPaySupplierPurchaseModal
					getRef={(r) => {
						oneRowRef.current = r
					}}
				/>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>
		</Layout>
	)
}

export default PaymentSupplierPage
