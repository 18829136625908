import React, { memo, useEffect, useState, useRef } from 'react'
import CheckByPhoneModalStyle from '../CheckByPhoneModal/CheckByPhoneModalStyle'
import Modal from 'react-modal'
import { ErrorModal } from '../index'
import { get } from 'lodash'
import { customFuncsApi } from '../../../api'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment'
import { Empty } from 'antd'
import { useTranslation } from 'react-i18next'
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
		maxHeight: '100vh',
		width: '85%',
	},
	overlay: {
		background: '#00000099',
	},
}

const ViewPaySupplierPurchaseModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
}) => {
	const { t } = useTranslation()

	const errorModalRef = useRef()

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [data, setData] = useState([])
	const [docEntry, setDocEntry] = useState('')

	useEffect(() => {
		const ref = {
			open: (t) => {
				setIsOpenModal(true)
				search(t.DocEntry)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])
	const search = (docEntry) => {
		setIsLoading(true)
		customFuncsApi
			.get(`XsSql/getPurchaseDetails?DocEntry=${docEntry}`, {
				headers: {
					Prefer: 'odata.maxpagesize=1000',
				},
			})
			.then((res) => {
				const resData = res.data.value
				setData(resData)
				setIsLoading(false)
			})
			.catch((err) => {
				if (err)
					errorModalRef.current?.open(
						get(err.response.data, 'error.message', ''),
					)
				setIsLoading(false)
			})
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CheckByPhoneModalStyle>
				{isLoading ? (
					<div className="flex items-center justify-center">
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					</div>
				) : (
					<>
						<div className="overflow-y-auto mb-8">
							<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
								<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
									<tr>
										<th scope="col" className="px-6 py-4">
											{t('itemCode')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('itemName')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('ИМЕИ')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('itemRegion')}
										</th>

										<th scope="col" className="px-6 py-4">
											{t('price')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('whsName')}
										</th>
									</tr>
								</thead>
								<tbody>
									{data.length > 0 ? (
										data.map((v, i) => (
											<tr
												className="bg-white border-b  hover:bg-gray-50 "
												key={i}
											>
												<td className="px-6 py-4 font-medium text-gray-900 ">
													{get(v, 'ItemCode', '')}
												</td>

												<td className="px-6 py-4 whitespace-nowrap">
													{`${get(v, 'Dscription', '')} ${get(v, 'U_Memory', '') ?? ''}  ${get(v, 'U_Color.Descr', '') ?? ''}  ${get(v, 'U_Condition', '') ?? ''}`}
												</td>
												<td className="px-6 py-4">
													{get(v, 'IntrSerial', '')}
												</td>
												<td className="px-6 py-4">
													{get(v, 'U_Region_Item', '')}
												</td>

												<td className="px-6 py-4">{get(v, 'Price', '')}</td>
												<td className="px-6 py-4">{get(v, 'WhsName', '')}</td>
											</tr>
										))
									) : (
										<tr>
											<td colSpan="10">
												<Empty
													description={t('noInfo')}
													image={Empty.PRESENTED_IMAGE_SIMPLE}
												/>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</>
				)}

				<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
			</CheckByPhoneModalStyle>
		</Modal>
	)
}

export default memo(ViewPaySupplierPurchaseModal)
